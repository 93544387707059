import React from 'react'
import { Link } from 'gatsby'
import { getConversion } from '../../api/images'
const PoiCard = ({ poi }) => {
    const { media, name, slug } = poi

    var featured_images = media.filter(media => media.collection_name === 'featured_images');
   
    return (
        <div className='col-lg-4 col-md-6'>
            <Link to={`/poi-details?slug=${slug}`}>
                <div className='single-areas-box'>
                    <div className='image'>
                        {featured_images.length > 0 ?
                            <img src={getConversion(featured_images[0])} alt={featured_images.featured_image_caption} />
                            : null
                        }
                        <div className='link-btn'>
                            <i className='bx bx-plus'></i>
                        </div>
                    </div>
                    <div className='content'>
                        <h3>{name}</h3>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default PoiCard