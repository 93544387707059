import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import PoiContent from '../components/Pois/PoiContent'
import { getPoi } from '../api/poi'
import { navigate } from 'gatsby'

const Poi = ({ location }) => {
    const [poi, setPoi] = useState(null)
    const [pois, setPois] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                setPoi(null)
                setPois(null)
                const data = await getPoi(slug)
                setPoi(data.poi)
                setPois(data.pois)
            } catch (error) {
                setPoi(null)
                setPois(null)
                navigate('/404')
            }
        })()
    }, [slug])

    if(!poi) return null

    return (
        <Layout title={poi.name}>
            <PageBanner
                pageTitle={poi.name}
                homePageText='Inicio'
                homePageUrl='/'
                activePageText={poi.name}
            />
            <PoiContent
                poi={poi}
                pois={pois}
            />
        </Layout>
    )
}

export default Poi
