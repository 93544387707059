import React from 'react'
import PoiCard from './PoiCard'

const PoiContent = ({ poi, pois }) => {
    const { body, summary } = poi

    return (
        <section className='page-area ptb-50'>
            <div className='container'>
                <div dangerouslySetInnerHTML={{ __html: summary }} />
                <div dangerouslySetInnerHTML={{ __html: body }} className='my-3' />
                <div className='row'>
                    {pois !== null && pois.map((item) => {
                        return (
                            <PoiCard
                                key={item.id}
                                poi={item}
                            />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default PoiContent